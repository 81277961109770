import React from "react";
import { Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { variableUseMutation } from '../hooks';
import { EntityRemoveButton, Localize } from 'components/service';
import Icons from 'components/icons';



const VariableActionField = ({ variableId, variableName }) => {

    const {
        _setMutationVariableDelete,
        loadingMutationVariableDelete,
    } = variableUseMutation.delete(variableId);


    const items =
        [
            {
                disabled: true,
                label: <Localize>MENUS_DROP.Label_UploadIcon</Localize>,
                key: '1',
                icon: <Icons.Upload />,
                // onClick: () => {}
            },
            {
                key: '2',
                icon:
                    <EntityRemoveButton
                        modalButton={
                            <span style={ { display: 'flex', alignItems: 'center' } } ><Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize></span>
                        }
                        nameEntity={ Localize({ children: "ENTITY.Modal_Title_Key" }).props.children }
                        dataNameEntity={ variableName }
                        loading={ loadingMutationVariableDelete }
                        deleteMutation={ _setMutationVariableDelete }
                        variables={ {
                            id: variableId,
                        } }
                    />,
            },
        ]


    return (
        <Dropdown
            // trigger={['click']}
            placement="bottomRight"
            menu={ { items } }
            overlayClassName="drop-more-menu"
            arrow
        >
            <EllipsisOutlined className="btn-more" />
        </Dropdown>
    )
};

export default VariableActionField;

