import RoleAddSelect from "./role-add-select";
import RoleColorSelect from "./role-color-select";


const RoleFormItems = {
    AddSelect: RoleAddSelect,
    ColorSelect: RoleColorSelect,
};

export default RoleFormItems;
