import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { ROLE_UPDATE, ROLE_CREATE } from "graphql/mutation/role-gql";
import RoleConst from '../role-const';
import { successNotification, errorNotification } from "components/request-result";



const useMutationRoleCreateUpdate = (id, redirect = true) => {

  const history = useHistory();

  const ROLE_CREATE_UPDATE = id ? ROLE_UPDATE : ROLE_CREATE;
  const roleCreateUpdate = id ? 'roleUpdate' : 'roleCreate';

  const [ _setRoleCreateUpdate, { loading } ] = useMutation(ROLE_CREATE_UPDATE,
    {
      update(cache, { data }) {

        const {
          [ roleCreateUpdate ]: {
            label,
            message,
          }
        } = data;


        redirect && history.push(RoleConst.basePath);

        successNotification({
          title: label,
          description: message
        });
      },
      onError(error) {
        errorNotification(error);
      }
    }
  );


  return {
    _setRoleCreateUpdate,
    loadingMutationRoleCreateUpdate: loading,
  }
};

export default useMutationRoleCreateUpdate;

