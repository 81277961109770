import Components from './light-components-style-config';

import { cssVariable } from "graphql/cache";
import { useReactiveVar } from "@apollo/client";


export const LightTheme = () => {

    const cssVar = useReactiveVar(cssVariable);

    const { Input, Select } = Components(cssVar);

    return {

        components: {
            Input,
            Select,
        }
    }
};