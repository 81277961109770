import VariableGroupField from "./variable-group-field";
import VariableActionField from './variable-action-field';
import VariableSlugField from './variable-slug-field';
import VariableTranslationField from './variable-translation-field';


const VariableFields = {

    Info : VariableGroupField,
    Action: VariableActionField,
    Slug: VariableSlugField,
    Translation: VariableTranslationField,

}

export default VariableFields;