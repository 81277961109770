import React from "react";
import { Form, Input, Button, Switch } from 'antd';
import { userUseMutation } from '../../hooks';
import UserFormItems from '../fields'
import { Localize, EntityRemoveButton } from "components/service";
import Role from "components/role";
import Icons from "components/icons";


const UserEditForm = ({ user }) => {

    const [ form ] = Form.useForm();

    const {
        _setUserCreateUpdate,
        loadingMutationUserCreateUpdate: loading,
    } = userUseMutation.createUpdate(user?.id);

    const {
        _setMutationUserDelete,
        loadingMutationUserDelete,
    } = userUseMutation.delete(user?.id);


    const valueFieldStatus = Form.useWatch('status', form);


    return (

        <Form
            key="edit-user-form"
            layout="vertical"
            form={ form }
            className="model-form edit-user-form"
            onFinish={ (values) => {

                _setUserCreateUpdate({
                    variables: {
                        input: {
                            id: user?.id ? +user.id : undefined,
                            ...values,
                            status: typeof values.status === 'string' ? user?.status : values?.status === true ? 'active' : 'block',
                        }
                    }
                })
            } }>

            <div className="switch-holder">
                <Form.Item
                    name="status"
                    label={ <Localize>FORMS.Input_Label_Status</Localize> }
                    initialValue={ user?.status === 'active' ? true : false }
                    valuePropName="checked"
                    className='form-item-switch'
                >
                    <Switch />
                </Form.Item>
                { valueFieldStatus ?
                    <span style={ { color: 'var(--switch_colorPrimary)' } } className="switch-status-text">Active</span>
                    :
                    <span style={ { color: 'var(--switch_colorTextQuaternary)' } } className="switch-status-text">Blocked</span> }
            </div>


            <div className="row-grid grid-gap-10 row-gap-0 col-md-2">

                <Form.Item
                    name="name"
                    label={ <Localize>FORMS.Input_Label_Name</Localize> }
                    initialValue={ user?.name }
                    rules={ [
                        { required: true, message: <Localize>FORM_RULES.Required_Name</Localize> }
                    ] }
                >
                    <Input name="name" placeholder="Christian" />
                </Form.Item>

                <Form.Item
                    name="surname"
                    label={ <Localize>FORMS.Input_Label_Surname</Localize> }
                    initialValue={ user?.surname }
                    rules={ [
                        { required: true, message: <Localize>FORM_RULES.Required_Surname</Localize> }
                    ] }
                >
                    <Input name="surname" /*placeholder="Glodek"*/ />
                </Form.Item>

                <Form.Item
                    name="email"
                    label={ <Localize>FORMS.Input_Label_Email</Localize> }
                    initialValue={ user?.email }
                    rules={ user?.id && [ { type: 'email', required: true, message: <Localize>FORM_RULES.Required_Email</Localize> } ] }
                >
                    <Input name="email"  /*placeholder="christin.glodek@fairnet.de" */ />
                </Form.Item>

                <Form.Item
                    name="phone"
                    label={ <Localize>FORMS.Input_Label_Phone</Localize> }
                    initialValue={ user?.phone }
                    rules={ [
                        { required: true, message: <Localize>FORM_RULES.Required_Phone</Localize> }
                    ] }
                >
                    <Input name="phone"  /*placeholder="01728559198" */ />
                </Form.Item>
            </div>

            <Role.Forms.Fields.AddSelect
                form={ form }
                initialValue={ user?.roles }
            />

            <div className="row-grid grid-gap-10 row-gap-0 col-md-2">
                <UserFormItems.LanguageSelect
                    name="lang_app"
                    label={ Localize({ children: "FORMS.Input_Label_IntrfaceLanguage" }).props.children }
                    initialValue={ user?.lang_app }
                />

                <UserFormItems.LanguageSelect
                    name="lang_api"
                    label={ Localize({ children: "FORMS.Input_Label_ContentLanguage" }).props.children }
                    initialValue={ user?.lang_api }
                />
            </div>


            <div className="form-btn-holder">
                { user?.id &&
                    <EntityRemoveButton
                        modalButton={
                            <Button icon={ <Icons.Delete /> }> <span className="ellipsis"><Localize>USER.Button_Text_Delete</Localize></span> </Button>
                        }
                        nameEntity={ Localize({ children: "ENTITY.Modal_Title_User" }).props.children }
                        dataNameEntity={ `${ user?.name } ${ user?.surname }` }
                        loading={ loadingMutationUserDelete }
                        deleteMutation={ _setMutationUserDelete }
                        variables={ {
                            id: user?.id,
                        } }
                    /> }
                <Button
                    className="btn-right"
                    type="primary"
                    htmlType="submit"
                    icon={ user?.id ? <Icons.Edit /> : <Icons.Plus /> }
                    loading={ loading }
                >
                    <span className="ellipsis"> { user?.id ? <Localize>GLOBAL.Button_Text_SaveChanges</Localize> : <Localize>GLOBAL.Button_Text_CreateNew</Localize> }</span>
                </Button>
            </div>
        </Form>
    );
};

export default UserEditForm;


