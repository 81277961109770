import React, { useState, useEffect } from 'react';
import { Menu, Tooltip } from 'antd';
import { Link } from "react-router-dom";
import Icons from "components/icons";
import { Localize } from "components/service";


import './sidebar.scss';


const Sidebar = ({ appParams, setToggleSider }) => {

    let {
        selectedKeys = [ 'dashboard' ],
        openKeys = [ 'dashboard' ]
    } = appParams.activeMenu;

    const [ openSubMenu, setOpenSubMenu ] = useState(openKeys);

    useEffect(() => {

        setOpenSubMenu(openKeys)

    }, [ appParams.activeMenu, openKeys ]);


    const items = [
        {

            key: 'users',
            label: <Tooltip overlayClassName="menu-tooltip" placement="right" title={ <Localize>MENUS_DROP.Label_Users</Localize> }> <Link to="/users"><Icons.Menu.Users /></Link> </Tooltip>,
            onClick: () => setToggleSider(true),
        },
        {
            disabled: true,
            key: 'organizers',
            label: <Tooltip overlayClassName="menu-tooltip" placement="right" title={ <Localize>MENUS_DROP.Label_Organizers</Localize> }> <Link to="/users"><Icons.Menu.Organizers /></Link> </Tooltip>,
            onClick: () => setToggleSider(true),
        },
        {
            key: 'suppliers',
            label: <Tooltip overlayClassName="menu-tooltip" placement="right" title={ <Localize>MENUS_DROP.Label_Suppliers</Localize> }> <Link to="/suppliers"><Icons.Menu.Suppliers /></Link> </Tooltip>,
            onClick: () => setToggleSider(true),
        },
        {
            disabled: true,
            key: 'teams',
            label: <Tooltip overlayClassName="menu-tooltip" placement="right" title={ <Localize>MENUS_DROP.Label_Teams</Localize> }> <Link to="/users"><Icons.Menu.Teams /></Link> </Tooltip>,
            onClick: () => setToggleSider(true),
        },
        {
            disabled: true,
            key: 'stands',
            label: <Tooltip overlayClassName="menu-tooltip" placement="right" title={ <Localize>MENUS_DROP.Label_Stands</Localize> }> <Link to="/users"><Icons.Menu.Stands /></Link> </Tooltip>,
            onClick: () => setToggleSider(true),
        },
        {
            disabled: true,
            key: 'equipment',
            label: <Tooltip overlayClassName="menu-tooltip" placement="right" title={ <Localize>MENUS_DROP.Label_Equipment</Localize> }> <Link to="/users"><Icons.Menu.Equipment /></Link> </Tooltip>,
            onClick: () => setToggleSider(true),
        },
        {
            disabled: true,
            key: 'flooring',
            label: <Tooltip overlayClassName="menu-tooltip" placement="right" title={ <Localize>MENUS_DROP.Label_Flooring</Localize> }> <Link to="/users"><Icons.Menu.Flooring /></Link> </Tooltip>,
            onClick: () => setToggleSider(true),
        },
        {
            key: 'variable-lists',
            label: <Tooltip overlayClassName="menu-tooltip" placement="right" title={ <Localize>MENUS_DROP.Label_Settings</Localize> }> <Link to="/variable-lists"><Icons.Menu.Settings /></Link> </Tooltip>,
            onClick: () => setToggleSider(true),
        },
    ];


    return (
        <>
            <Menu
                items={ items }
                className='sidebar-menu'
                // theme="dark"
                mode="inline"
                selectedKeys={ selectedKeys }
                openKeys={ openSubMenu }
                onOpenChange={ (keys) => setOpenSubMenu(keys) }
                style={ { borderRight: 0 } }>
            </Menu>
        </>
    );
};

export default Sidebar;