import SupplierInfoField from "./supplier-info-field";
import SupplierActionField from './supplier-action-field';



const SupplierFields = {
    Info : SupplierInfoField,
    Action: SupplierActionField,
}

export default SupplierFields;