import React, { useEffect } from "react";
import { Form, Input } from 'antd';
import { variableUseMutation } from '../hooks';



const VariableTranslationField = ({ id, lang, initialValue, variableList, variables }) => {

    const [ form ] = Form.useForm();


    const {
        _setVariableCreateUpdate,
    } = variableUseMutation.createUpdate({ id, variables });


    /*eslint-disable */
    useEffect(() => {
        form.setFieldsValue({ value: initialValue });
    }, [ initialValue ]);
    /*eslint-enable */


    const handleBlur = (value) => {

        if (initialValue !== value && value !== "")
        {
            _setVariableCreateUpdate({
                variables: {
                    input: {
                        id: id ? id : undefined,
                        group_name: variableList?.group_name,
                        slug: variableList?.slug,
                        value: value,
                        lang,
                        editable: variableList?.editable,
                    }
                }
            });
        }
    };


    return (
        <Form
            key="variable-translation-field"
            className="form-variable-translate"
            layout="vertical"
            form={ form }
        >
            <Form.Item name="value">
                <Input onBlur={ e => handleBlur(e.target.value) } name="value" style={ { width: '100%', fontWeight: '400' } } />
            </Form.Item>
        </Form>

    );
}


export default VariableTranslationField;