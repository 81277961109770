import React from 'react';
import { createRoot } from 'react-dom/client';
import { ConfigProvider } from 'antd';
import { ApolloProvider } from "@apollo/client";

import client from "graphql/client";

import App from 'components/app';
import Auth from "components/auth";
import Router from "components/routes";
import customizeTheme from 'components/customize-theme';
import { ScrollToTop } from 'components/service';

import { cssVariable } from "graphql/cache";
import { getCSSVariables } from 'utils';

import "antd/dist/reset.css";
import "scss/default.scss";



const RootComponent = () => {

  const cssVar = getCSSVariables();
  cssVariable(cssVar)


  return (
    <ConfigProvider theme={ customizeTheme.DarkTheme() }>
      <ApolloProvider client={ client }>
        <Router>
          <Auth>
            <ScrollToTop />
            <App />
          </Auth>
        </Router>
      </ApolloProvider>
    </ConfigProvider>
  );
};

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(<RootComponent />);
