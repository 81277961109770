import SupplierFields from "../fields";
import { Localize } from "components/service";


const supplierColumns = [
    {
        title: <Localize>TABLES.Column_Title_ID</Localize>,
        dataIndex: 'id',
        columnIndex: 'ID',
        sorter: true,
        width: '4%'
    },
    {
        title: <Localize>TABLES.Column_Title_SupplierName</Localize>,
        dataIndex: 'title',
        columnIndex: 'TITLE',
        sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_Type</Localize>,
        dataIndex: 'type',
        columnIndex: 'TYPE',
        sorter: true
    },

    {
        title: <Localize>TABLES.Column_Title_Number</Localize>,
        dataIndex: 'number',
        columnIndex: 'NUMBER',
        sorter: true
    },

    {
        title: <Localize>TABLES.Column_Title_UsersNumber</Localize>,
        dataIndex: 'usersCount',
        columnIndex: 'USERS_COUNT',
        // sorter: true
    },

    {
        // title: '',
        dataIndex: 'action',
        align: 'right',
        width: '4%',
    }
];


const supplierData = (supplier) => {

    if (!supplier)
    {
        return [];
    }

    return supplier.map(supplier => {

        return {
            key: supplier.id,
            id: supplier.id,
            title: <SupplierFields.Info supplier={ supplier } />,
            type: supplier.type ?? <strong>&mdash;</strong>,
            number: supplier.number ?? <strong>&mdash;</strong>,
            usersCount: supplier.usersCount ?? <strong>&mdash;</strong>,
            action: <SupplierFields.Action supplierId={ supplier.id } supplierName={ supplier.title } />
        };

    });
};


const SuppliersTableHelper = {
    columns: supplierColumns,
    data: supplierData,
}

export default SuppliersTableHelper;