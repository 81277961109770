import React from "react";
import { Switch, Route, Redirect } from 'react-router-dom';
import UsersRoute from './user/users-route';
import RolesRoute from './role/roles-route';
import SuppliersRoute from './supplier/suppliers-route';
import VariablesRoute from './variable/variables-route';
import { Pages } from "components/pages";


const AppRoutes = () => {

    return (

        <Switch>
            <Route path="/" exact>
                <Redirect to="/users" />
            </Route>

            <Route path="/user-roles">
                <RolesRoute />
            </Route>

            <Route path="/users">
                <UsersRoute />
            </Route>

            <Route path="/suppliers">
                <SuppliersRoute />
            </Route>

            <Route path="/variable-lists">
                <VariablesRoute />
            </Route>

            <Route path="*">
                <Pages.Page404 />
            </Route>
        </Switch>
    );
};

export default AppRoutes;