import { useMutation } from "@apollo/client";
import { ROLE_REMOVE_USER } from "graphql/mutation/role-gql";
import { SUPPLIER_REMOVE_USER } from "graphql/mutation/supplier-gql";
import { GET_USERS } from 'graphql/query/user-gql';

import { successNotification, errorNotification } from "components/request-result";


const useMutationUserRemove = ({ mutation }) => {

  let USER_REMOVE;

  switch (mutation)
  {
    case 'roleRemoveUser':
      USER_REMOVE = ROLE_REMOVE_USER;
      break;
    case 'supplierRemoveUser':
      USER_REMOVE = SUPPLIER_REMOVE_USER;
      break;
    // Add more cases here if needed
    default:
      USER_REMOVE = null;
  }

  const [ _setMutationUserRemove, { loading } ] = useMutation(USER_REMOVE,
    {
      update(cache, { data }) {

        const {
          [ mutation ]: {
            label,
            message
          }
        } = data;


        successNotification({
          title: label.toUpperCase(),
          description: message
        });
      },

      refetchQueries: [ GET_USERS ],

      onError(error) {
        errorNotification(error);
      }
    });

  return {
    _setMutationUserRemove,
    loadingMutationUserRemove: loading,
  }
};

export default useMutationUserRemove;
