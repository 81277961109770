import { Switch, Form } from 'antd';
import { userUseMutation } from '../hooks';


const UserStatusField = ({ id, status }) => {

    const [ form ] = Form.useForm();

    const {
        _setUserCreateUpdate,
    } = userUseMutation.createUpdate(id, false);

    const valueFieldStatus = Form.useWatch('status', form);


    return (
        <Form form={ form } className="">

            <div className="switch-holder">
                <Form.Item
                    name="status"
                    initialValue={ status === 'active' ? true : false }
                    style={ { marginBottom: 0 } }
                >
                    <Switch
                        checked={ status === 'active' ? true : false }
                        onChange={
                            (checked) => {
                                _setUserCreateUpdate({
                                    variables: {
                                        input: {
                                            id,
                                            status: checked ? 'active' : 'block',
                                        }
                                    }
                                })
                            }
                        } />

                </Form.Item>

                { valueFieldStatus ?
                    <span style={ { color: 'var(--switch_colorPrimary)' } } className="switch-status-text">Active</span>
                    :
                    <span style={ { color: 'var(--switch_colorTextQuaternary)' } } className="switch-status-text">Blocked</span>
                }
            </div>
        </Form>

    );
};

export default UserStatusField;
