import React, { useState } from "react";
import { Button, Input } from "antd";
import { useRouteMatch } from "react-router-dom";

import { GET_USERS } from 'graphql/query/user-gql';

import Role from 'components/role';
import Icons from "components/icons";
import { useBreadCrumbs } from "components/use-hooks";
import { Tables } from "components/layout";
import { Localize } from "components/service";

const { Search } = Input;


const RoleUsersSubPage = ({ role }) => {

    const basePath = Role.Const.basePath;
    const tableHelper = Role.Helpers.TableUsers;

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Roles" }).props.children,
            path: `${ basePath }`
        },
        {
            label: role?.title,
            path: `${ basePath }/${ role?.id }`
        },
        {
            label: Localize({ children: "BREADCRUMBS.Label_Users" }).props.children,
            path: `${ basePath }/${ role?.id }/users`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    const roleUsers = { column: 'ID', operator: "EQ", value: parseInt(role.id) }

    let match = useRouteMatch(`${ basePath }/page/:pageNum`);

    const objOrderBy = [ { column: "ID", order: "DESC" } ];

    const [ searchText, setSearchText ] = useState("");


    return (
        <div className="col-left">
            <Tables.Main
                model="users"
                query={ GET_USERS }
                routeUrl={ `${ basePath }` }
                currentPage={ match ? parseInt(match.params.pageNum) : 1 }
                searchText={ searchText }
                objOrderBy={ objOrderBy }
                varSet={ { whereRoles: roleUsers, perPage: 30 } }
                currentObj={ role }
                tableHelper={ tableHelper }
            >
                <div className="table-action-bar">

                    <div className="col">
                        <Search
                            className="filter-search"
                            prefix={ <Icons.Search /> }
                            onChange={ (e) => setSearchText(e.target.value) }
                        />
                    </div>

                    <div className="col">
                        <Button
                            disabled
                            type="primary"
                            icon={ <Icons.Plus /> }
                        // onClick={ () => history.push(`${ basePath }/create`) }
                        >
                            <span className="ellipsis"><Localize>GLOBAL.Button_Text_AddNew</Localize></span>
                        </Button>
                    </div>
                </div>
            </Tables.Main>
        </div>
    )
}

export default RoleUsersSubPage;