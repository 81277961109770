import UserInfoField from "./user-info-field";
import UserRoleField from "./user-role-field";
import UserActionField from './user-action-field';
import UserPhoneField from './user-phone-field';
import UserStatus from './user-status-field';
import UserRemoveField from './user-remove-field';


const UserFields = {
    Info : UserInfoField,
    Role : UserRoleField,
    Phone: UserPhoneField,
    Status: UserStatus,
    Action: UserActionField,
    Remove: UserRemoveField
}

export default UserFields;