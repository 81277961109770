import RoleRoleField from "./role-role-field";
import RoleActionField from './role-action-field';


const RoleFields = {

    Info : RoleRoleField,
    Action: RoleActionField,

}

export default RoleFields;