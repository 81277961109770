import React from "react";
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import SubPage from 'components/pages/role/sub-pages';


const RoleRoute = ({ role, history }) => {

    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={ `${ path }/users` } exact>
                <SubPage.Users role={ role } />
            </Route>

            <Route path={ `${ path }/users/page/:pageNum` } exact>
                <SubPage.Users role={ role } />
            </Route>

            <Route path={ `${ path }` }>
                <SubPage.OverView role={ role } history={ history } />
            </Route>
        </Switch>
    );
};

export default RoleRoute;