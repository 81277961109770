import { LoginPage, ForgotPasswordPage } from "./authorization";
import { UsersPage, UserPage } from "./user";
import { RolesPage, RolePage } from "./role";
import { SuppliersPage, SupplierPage } from "./supplier";
import { VariableGroupPage, VariableListPage } from "./variable";

import Dashboard from "./dashboard/dashboard-page";
import Page404 from "./404/404-page";


import "./pages.scss";



export const Pages = {
    Dashboard,
    Users: UsersPage,
    User: UserPage,
    Roles: RolesPage,
    Role: RolePage,
    Supliers: SuppliersPage,
    Suplier: SupplierPage,
    VariableGroup: VariableGroupPage,
    VariableList: VariableListPage,
    Page404
};

export const GuestPages = {
    LoginPage,
    ForgotPasswordPage,
}