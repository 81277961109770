import React, { useState, useEffect } from "react";
import { Waypoint } from 'react-waypoint'
import { Form, Checkbox, Skeleton, Button } from "antd";
// import { PlusOutlined } from '@ant-design/icons';
import { useQuery } from "@apollo/client";
import { GET_ROLES_CURSOR } from "graphql/query/role-gql";
// import { GET_USERS_CURSOR } from "graphql/query/user-gql";
import MultiSelect from 'components/layout/form-elements/multi-select';
import EditableTagGroup from 'components/layout/form-elements/editable-tag-group';
import { ModalStandard, cursorPagination, Localize } from "components/service";
import { useDebounce } from "components/use-hooks";
import { Loader } from "components/request-result";
import Icons from "components/icons";
import { helperFunc } from 'utils';

import './role-add-select.scss';


const RoleAddSelect = ({
    form,
    initialValue,
    model = "rolesCursor",
    name = 'role_id',
    label = Localize({ children: "FORMS.Input_Label_Role" }).props.children
}) => {


    const roleListInit = initialValue?.map((role) => role?.id.toString());
    const [ roleList, setRoleList ] = useState(roleListInit);
    const [ searchRole, setSearchRole ] = useState("");

    useEffect(() => {
        form.setFieldsValue({ [ name ]: roleList });
    }, [ form, name, roleList ]);

    let { data, loading, fetchMore } = useQuery(GET_ROLES_CURSOR, {
        variables: {
            text: useDebounce(searchRole),
            first: 25,
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true
    });


    const {
        fetchMoreAction,
        nodes,
        pageInfo
    } =
        cursorPagination({
            model,
            data,
            loading,
            fetchMore
        });


    const initialValues = initialValue && initialValue?.map(
        ({ id, title }) => ({
            value: id,
            label: title
        })
    )


    return (
        <Form.Item
            name={ name }
            label={ label }
        >

            <EditableTagGroup
                setTags={ setRoleList }
                tags={ initialValues ? helperFunc.getLabel(roleList, [ ...new Set([ ...nodes, ...initialValues ]?.map(item => JSON.stringify(item))) ]?.map(item => JSON.parse(item))) : helperFunc.getLabel(roleList, nodes) }
            >
                <ModalStandard
                    width={ 400 }
                    extraClass={ 'modal-form' }
                    modalButton={
                        <div>
                            <Button
                                type="text"
                                icon={ <Icons.Plus /> }>
                                <span className="ellipsis"><Localize>MODAL.Button_Text_AddRole</Localize></span>
                            </Button>
                        </div>
                    }
                >

                    <strong className="modal-title"><span className="text"><Localize>FORMS.Title_AddRoles</Localize></span></strong>

                    <MultiSelect
                        setSearch={ setSearchRole }
                        placeholder={ Localize({ children: "SEARCH.Input_Placeholder_Role" }).props.children }
                    >
                        { searchRole && loading ? <Skeleton active={ true } paragraph={ { rows: 6 } } /> :
                            <Checkbox.Group
                                className='group-checkbox'
                                options={ initialValues ? [ ...new Set([ ...nodes, ...initialValues ]?.map(item => JSON.stringify(item))) ]?.map(item => JSON.parse(item)) : nodes }
                                defaultValue={ roleList }
                                onChange={ value => setRoleList(value) } />
                        }

                        { pageInfo?.hasNextPage &&

                            <>
                                { loading && <Loader style={ { marginTop: '-30px' } } /> }
                                <Waypoint onEnter={ () => fetchMoreAction() } />
                            </>
                        }

                    </MultiSelect>

                </ModalStandard>

            </EditableTagGroup>
        </Form.Item >
    )
};

export default RoleAddSelect;


