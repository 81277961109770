const english = {
    'USER.Button_Text_Delete': 'Delete User',
    'ROLE.Button_Text_Delete': 'Delete Role',
    'SUPPLIER.Button_Text_Delete': 'Delete Supplier',
    'TABLES.Column_Title_Name': 'Name',
    'TABLES.Column_Title_Phone': 'Phone',
    'TABLES.Column_Title_Email': 'Email',
    'TABLES.Column_Title_Company': 'Company',
    'TABLES.Column_Title_Supplier': 'supplier',
    'TABLES.Column_Title_User': 'User',
    'TABLES.Column_Title_Time': 'Time',
    'TABLES.Column_Title_JobType': 'Job type',
    'TABLES.Column_Title_Roles': 'Roles',
    'TABLES.Column_Title_Teams': 'Teams',
    'TABLES.Column_Title_Type': 'Type',
    'TABLES.Column_Title_Message': 'Message',
    'TABLES.Column_Title_Label': 'Label',
    'TABLES.Column_Title_Stands': 'Stands',
    'TABLES.Column_Title_Title': 'title',
    'TABLES.Column_Title_Materials': 'materials',
    'TABLES.Column_Title_Status': 'status',
    'TABLES.Column_Title_Description': 'description',
    'TABLES.Column_Title_Created': 'created',
    'TABLES.Column_Title_InProgress': 'in progress',
    'TABLES.Column_Title_Completed': 'completed',
    'TABLES.Column_Title_ID': 'ID',
    'TABLES.Column_Sorting_Tooltip_Ascending': 'Click to sort ascending',
    'TABLES.Column_Sorting_Tooltip_Descending': 'Click to sort descending',
    'TABLES.Column_Sorting_Tooltip_Cancel': 'Click to cancel sorting',
    'TABLES.Column_Title_FileName': 'file name',
    'TABLES.Column_Title_RoleName': 'Role name',
    'TABLES.Column_Title_NumberRights': 'Number of rights',
    'TABLES.Column_Title_NumberUsers': 'Number of users',
    'TABLES.Column_Title_SupplierName': 'Supplier name',
    'TABLES.Column_Title_Number': 'Number',
    'TABLES.Column_Title_UsersNumber': 'Users Number',
    'TABLES.Column_Title_KeyAmount': 'Key amount',
    'TABLES.Column_Title_GroupName': 'Group name',
    'TABLES.Column_Title_Key': 'Key',
    'TABLES.Column_Title_Editable': 'Editable',
    'PAGES.Title_CreateGroup': 'Create Group',
    'FORM_RULES.Min_NewPassword': 'Password must be at least { minLength } characters',
    'FORM_RULES.Required_NewPassword': 'Please input new Password',
    'FORM_RULES.Required_ConfirmPassword': 'Please confirm new password',
    'FORM_RULES.Required_ConfirmPasswordMatch': 'The entered passworts do not match.',
    'FORM_RULES.Required_Name': 'Please input name',
    'FORM_RULES.Required_Surname': 'Please input surname',
    'FORM_RULES.Required_Email': 'The input is not valid E-mail',
    'FORM_RULES.Required_Choose': 'Please Choose',
    'FORM_RULES.Required_Phone': 'Please input phone',
    'FORM_RULES.Required_Title': 'Please input title',
    'FORM_RULES.Required_Number': 'Please input number',
    'FORM_RULES.Required_Where': 'Please choose where to change the time',
    'FORM_RULES.Required_Password': 'Please input your Password!',
    'FORM_RULES.Required_RoleName': 'Please input role name',
    'FORM_RULES.Required_Color': 'Please input color',
    'FORM_RULES.Required_SupplierName': 'Please input supplier name',
    'FORM_RULES.Required_Key': 'Please input key',
    'FORMS.Input_Label_Password': 'Password',
    'FORMS.Input_Label_Login': 'login',
    'FORMS.Input_Label_NewPassword': 'New password',
    'FORMS.Input_Label_ConfirmPassword': 'Confirm new password',
    'FORMS.Input_Label_Language': 'Language',
    'FORMS.Input_Label_Name': 'Name',
    'FORMS.Input_Label_Surname': 'Surname',
    'FORMS.Input_Label_Phone': 'Phone',
    'FORMS.Input_Label_Email': 'Email',
    'FORMS.Input_Label_CrmRights': 'CRM Rights',
    'FORMS.Input_Label_Attachment': 'Attachment',
    'FORMS.Input_Label_Title': 'title',
    'FORMS.Input_Label_Number': 'number',
    'FORMS.Input_Label_Where': 'Where',
    'FORMS.Input_Label_Time': 'time',
    'FORMS.Input_Label_Description': 'description',
    'FORMS.Input_Label_ChooseType': 'Choose type',
    'FORMS.Input_Label_Status': 'Status',
    'FORMS.Input_Label_ChooseStatus': 'Choose status',
    'FORMS.Input_Label_HallToMoveStandsTo': 'hall to move stands to',
    'FORMS.Input_Label_NewFinishingTime': 'New deadline',
    'FORMS.Input_Label_Company': 'company',
    'FORMS.Input_Label_IntrfaceLanguage': 'Interface language',
    'FORMS.Input_Label_ContentLanguage': 'Content language',
    'FORMS.Input_Label_RoleName': 'Role name',
    'FORMS.Input_Label_Color': 'Color',
    'FORMS.Input_Label_Role': 'role',
    'FORMS.Input_Label_SupplierName': 'Supplier name',
    'FORMS.Input_Label_GroupName': 'Group name',
    'FORMS.Input_Label_Key': 'Key',
    'FORMS.Input_Label_EditableKey': 'Editable key',
    'FORMS.Title_ChangePassword': 'Change password',
    'FORMS.Title_SendPassword': 'Send password',
    'FORMS.Title_AddUsers': 'Add users',
    'FORMS.Title_ChooseType': 'Choose type',
    'FORMS.Title_Text_Filters': 'Filters',
    'FORMS.Title_Text_CreateJob': 'Create job',
    'FORMS.Title_AddFile': 'Add file',
    'FORMS.Title_EditFile': 'Edit file',
    'FORMS.Title_AddRoles': 'Add roles',
    'FORMS.Title_CreateNewKey': 'Create new key',
    'FORMS.Title_EditKey': 'Edit key',
    'FORMS.Select_OptionLabel_English': 'English',
    'FORMS.Select_OptionLabel_Deutsch': 'Deutsch',
    'FORMS.Select_OptionLabel_ChooseColor': 'Choose Color',
    'FORMS.Input_Placeholder_Email': 'Email',
    'FORMS.Input_Placeholder_Password': 'Password',
    'FORMS.Input_Placeholder_Choose': 'Please Choose',
    'FORMS.Input_Placeholder_ChooseColor': 'Choose Color',
    'FORMS.Input_Placeholder_Where': 'Please choose where to change the time',
    'FORMS.Input_Placeholder_ShortDescription': 'Enter short description',
    'FORMS.Input_Placeholder_ChooseStatus': 'Choose status',
    'FORMS.Input_Placeholder_ChooseType': 'Choose type',
    'FORMS.Input_Placeholder_ExhibitionName': 'Please input exhibition name',
    'FORMS.Input_Placeholder_HallName': 'Please input hall name',
    'FORMS.Input_Placeholder_SelectDate': 'Select date',
    'FORMS.Input_Placeholder_StartDate': 'Start date',
    'FORMS.Input_Placeholder_EndDate': 'End date',
    'FORMS.Input_Placeholder_Team': 'Team',
    'FORMS.Input_Placeholder_Name': 'Name',
    'FORMS.Headig_Text_AppRights': 'App Rights',
    'FORMS.Headig_Text_Special': 'Special',
    'FORMS.Headig_Text_User': 'User',
    'FORMS.Headig_Text_Exhibitions': 'Exhibitions',
    'SEARCH.Input_Placeholder_User': 'Search for users',
    'SEARCH.Input_Placeholder_Role': 'Search for roles',
    'SEARCH.Input_Placeholder_Table_User': 'Search for name, email, phone',
    'SEARCH.Input_Placeholder_MultiSelect': 'Search',
    'GLOBAL.Button_Text_Save': 'Save',
    'GLOBAL.Button_Text_Send': 'Send',
    'GLOBAL.Button_Text_SaveSend': 'Save and send',
    'GLOBAL.Button_Text_SaveChanges': 'Save changes',
    'GLOBAL.Button_Text_CreateNew': 'Create new',
    'GLOBAL.Button_Text_Create': 'Create',
    'GLOBAL.Button_Text_Update': 'update',
    'GLOBAL.Button_Text_Reimport': 'reimport',
    'GLOBAL.Button_Text_Cancel': 'Cancel',
    'GLOBAL.Button_Text_Delete': 'Delete',
    'GLOBAL.Button_Text_Remove': 'Remove',
    'GLOBAL.Button_Text_Confirm': 'Confirm',
    'GLOBAL.Button_Text_AddFile': 'Add file',
    'GLOBAL.Button_Text_UploadFile': 'Upload file',
    'GLOBAL.Button_Text_Login': 'Login',
    'GLOBAL.Button_Text_BackHome': 'Back Home',
    'GLOBAL.Modal_Text_UserSendPass': 'Are you sure you want to send a{ br }new password?',
    'GLOBAL.Modal_Text_RemoveText': 'Are you sure you want to remove the { deleteObjectType }?',
    'GLOBAL.Modal_Text_DeleteText': 'Are you sure you want to delete the { deleteObjectType }?',
    'GLOBAL.Modal_Text_ResetFilters': 'Reset filters',
    'GLOBAL.Button_Text_ExportCsv': 'export',
    'GLOBAL.Button_Text_DownloadReport': 'download report',
    'GLOBAL.Button_Text_Actions': 'Actions',
    'GLOBAL.Button_Text_AddNew': 'Add new',
    'GLOBAL.Button_Text_AddKey': 'Add key',
    'GLOBAL.Button_Text_Creat': 'Create',
    'GLOBAL.Button_Text_DeleteGroup': 'Delete group',
    'GLOBAL.Link_Text_Download': 'Download',
    'GLOBAL.Link_Text_Unassigned': 'unassigned',
    'GLOBAL.Link_Text_Assigned': 'Assigned',
    'GLOBAL.Text_NoData': 'No Data',
    'GLOBAL.Text_Status_Disabled': 'Disabled',
    'GLOBAL.Text_Status_InProgress': 'In progress',
    'GLOBAL.Text_Status_Completed': 'Completed',
    'GLOBAL.Text_Status_ChangePassUser': 'Password sent by { user } at { dateTime }',
    'MODAL.Button_Text_Choose': 'Choose',
    'MODAL.Button_Text_AddRole': 'Add role',
    'FORMS.Button_Text_ApplyFilters': 'Filters',
    'FORMS.Button_Text_ResetFilters': 'Reset',
    'ENTITY.Modal_Title_User': 'user',
    'ENTITY.Modal_Title_Role': 'role',
    'ENTITY.Modal_Title_Document': 'document',
    'ENTITY.Modal_Title_Downloading': 'Wait is downloading the file...',
    'ENTITY.Modal_Title_Importing': 'The event file is importing...',
    'ENTITY.Modal_Title_Imported': 'The event file has been imported:',
    'ENTITY.Modal_Title_Supplier': 'supplier',
    'ENTITY.Modal_Title_Key': 'key',
    'ENTITY.Modal_Title_Group': 'group',
    'MENUS.Label_Users': 'users',
    'MENUS_DROP.Label_Users': 'Users',
    'MENUS_DROP.Label_Logout': 'Logout',
    'MENUS_DROP.Label_Edit': 'Edit',
    'MENUS_DROP.Label_SendPass': 'Send password',
    'MENUS_DROP.Label_Delete': 'Delete',
    'MENUS_DROP.Label_Mark': 'Mark',
    'MENUS_DROP.Label_InProgress': 'in progress',
    'MENUS_DROP.Label_Open': 'open',
    'MENUS_DROP.Label_Done': 'done',
    'MENUS_DROP.Label_Online': 'online',
    'MENUS_DROP.Label_Offline': 'offline',
    'MENUS_DROP.Label_Organizers': 'organizers',
    'MENUS_DROP.Label_Suppliers': 'suppliers',
    'MENUS_DROP.Label_Teams': 'teams',
    'MENUS_DROP.Label_Stands': 'stands',
    'MENUS_DROP.Label_Equipment': 'equipment',
    'MENUS_DROP.Label_Flooring': 'flooring',
    'MENUS_DROP.Label_Settings': 'settings',
    'MENUS_DROP.Label_MyProfile': 'My profile',
    'MENUS_DROP.Label_UploadIcon': 'Upload icon',
    'NAVBAR_PAGES.Label_Overview': 'overview',
    'NAVBAR_PAGES.Label_Dealers': 'dealers',
    'NAVBAR_PAGES.Label_Logs': 'logs',
    'NAVBAR_PAGES.Label_Users': 'users',
    'NAVBAR_PAGES.Label_UserRoles': 'user roles',
    'NAVBAR_PAGES.Label_Documents': 'documents',
    'NAVBAR_PAGES.Label_VariableLists': 'variable lists',
    'BREADCRUMBS.Label_Logs': 'logs',
    'BREADCRUMBS.Label_User': 'user',
    'BREADCRUMBS.Label_Users': 'users',
    'BREADCRUMBS.Label_Roles': 'user roles',
    'BREADCRUMBS.Label_Create': 'create',
    'BREADCRUMBS.Label_Documents': 'documents',
    'BREADCRUMBS.Label_Suppliers': 'suppliers',
    'BREADCRUMBS.Label_VariableLists': 'variable lists',
    'PAGES.Title_Dashboard': 'Dashboard',
    'PAGES.Title_Users': 'Users',
    'PAGES.Title_CreateUser': 'Create User',
    'PAGES.Title_CreateRole': 'Create User Role',
    'PAGES.Title_CreateSupplier': 'Create Supplier',
    'PAGES.Title_Suppliers': 'Suppliers',
    'PAGES.Title_Settings': 'Settings',
    'PAGES.Text_NoExisting': 'There is no existing last minute jobs in the stand',
    'PAGES.Text_404': 'Sorry, the page you visited does not exist.',
};

export default english;