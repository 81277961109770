import React from "react";
import { Form, Input, Button } from "antd";
import { Localize } from "components/service";


const UserFiltersForm = ({ action, usersFilters, setCount, setFilters, setFilterList, filterList, gqlBuilderWhere }) => {


    const initialValFormatted = (date, fieldName) => {
        const val = date?.[ fieldName ]?.value;
        if (val) return val.replace(/[^a-z0-9]/gi, '');
    }

    const onChangeSetFilter = (e, name, value) => {

        if (e)
        {
            name = e.target.name
            value = e.target.value
        }

        if (name === 'NAME')
        {
            value.toString().length > 0 ?

                setFilterList({
                    ...filterList,
                    [ name ]: {
                        column: name,
                        operator: "LIKE",
                        value: `${ value }%`
                    }
                }) : setFilterList({ ...filterList, [ name ]: {} });
        }

        if (name === 'ROLE_ID')
        {
            value.toString().length > 0 ?

                setFilterList({
                    ...filterList,
                    [ name ]: {
                        column: name,
                        operator: "IN",
                        value: value
                    }
                }) : setFilterList({ ...filterList, [ name ]: {} });
        }
    }


    return (

        <div className="ant-form ant-form-vertical filters-form">

            <Form.Item
                initialValue={ initialValFormatted(usersFilters, 'NAME') }
                name="NAME"
                label={ <Localize>FORMS.Input_Label_Name</Localize> }
                onChange={ onChangeSetFilter }
            >
                <Input name="NAME" placeholder={ `${ Localize({ children: "FORMS.Input_Placeholder_Name" }).props.children }` } />
            </Form.Item>

            <div className="form-btn-holder">
                <Button
                    type="primary"
                    htmlType="submit"
                    onClick={ () => {
                        setFilters(gqlBuilderWhere({ ...usersFilters, ...filterList }));
                        setCount({ ...usersFilters, ...filterList });
                        action();
                    } }
                >
                    <Localize>FORMS.Button_Text_ApplyFilters</Localize>
                </Button>
                <Button
                    className="light-bg btn-right"
                    htmlType="submit"
                    onClick={ () => {
                        setFilterList({});
                        setCount({});
                        setFilters(gqlBuilderWhere({}));
                        localStorage.removeItem("usersFilters");
                    } }
                >
                    <Localize>FORMS.Button_Text_ResetFilters</Localize>
                </Button>
            </div>
        </div>
    )
}

export default UserFiltersForm;