import React from "react";
import Role from "components/role";
import { useQuery } from "@apollo/client";
import { GET_ROLE } from "graphql/query/role-gql";
import { PageWrapDefault } from "components/layout";
import { useActiveMenuItem } from "components/use-hooks";
import RoleRoute from 'components/routes/role/role-route';
import { Localize } from "components/service";


const RolePage = ({ match, history }) => {

    useActiveMenuItem([ "users" ], [ "users" ]);

    const basePath = Role.Const.basePath;
    const path = history.location.pathname.split('/').pop()
    const roleID = match ? parseInt(match.params.id) : null;

    const { data: { role } = {}, loading } = useQuery(GET_ROLE, {
        skip: !roleID,
        variables: {
            id: roleID
        }
    });

    const pageNavbar = [
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Overview" }).props.children,
            path: "overview"
        },
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Users" }).props.children,
            path: "users"
        },
    ];


    return (
        <PageWrapDefault
            className="page-role"
            loading={ loading }
            title={ path === 'create' ? <Localize>PAGES.Title_CreateRole</Localize> : !loading && role ? `${ role.title }` : 'Page 404' }
            dataExist={ path === 'create' ? true : !loading && role }
            pageNavbar={ !loading && role ? pageNavbar : false }
            staticPath={ `${basePath}/${ roleID }` }
        >
            <RoleRoute
                role={ role }
                history={ history }
                loading={ loading } />

        </PageWrapDefault>
    );
};

export default RolePage;
