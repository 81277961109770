import React from "react";

import { userUseMutation } from '../hooks';
import { EntityRemoveButton, Localize } from 'components/service';
import Icons from 'components/icons';


const UserRemoveField = ({ userId, userName, modelID, mutation, disabled = false }) => {


    const {
        _setMutationUserRemove,
        loadingMutationUserRemove,
    } = userUseMutation.remove({ mutation });


    return (
        <EntityRemoveButton
            okText={ Localize({ children: "GLOBAL.Button_Text_Remove" }).props.children }
            disabled={ disabled }
            modalButton={
                <span
                    style={ {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'end',
                        fontSize: '22px',
                        color: !disabled ? 'var(--table_colorIconRemove)' : 'var(--table_colorIconRemoveDisabled)',
                        cursor: !disabled ? 'pointer' : 'default',
                    } } >
                    <Icons.Close />
                </span>
            }
            nameEntity={ Localize({ children: "ENTITY.Modal_Title_User" }).props.children }
            dataNameEntity={ userName }
            loading={ loadingMutationUserRemove }
            deleteMutation={ _setMutationUserRemove }
            variables={ {
                user_id: userId,
                ...modelID
            } }
        />
    )
};

export default UserRemoveField;

