import UserForms from '../../forms';
import UserActionItems from '../fields';
import Icons from 'components/icons'
import { ModalStandard, Localize } from 'components/service';
import './user-action-list.scss';


const UserActionList = ({ userID }) => {


    return (
        <ul className='action-list indents'>
            <ModalStandard
                width={ 400 }
                modalButton={ <li><Icons.SendPass /><span><Localize>FORMS.Title_SendPassword</Localize></span></li> }>

                <UserActionItems.SendPass userID={ userID } />

            </ModalStandard>

            <ModalStandard
                width={ 400 }
                extraClass={ 'modal-form' }
                modalButton={ <li><Icons.ChangePass /><span><Localize>FORMS.Title_ChangePassword</Localize></span></li> }>

                <strong className='modal-title'><Localize>FORMS.Title_ChangePassword</Localize></strong>

                <UserForms.ChangePassword userID={ userID } />

            </ModalStandard>
        </ul>
    );
}

export default UserActionList;