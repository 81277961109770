import VariableFields from "../fields";
import { Localize } from "components/service";



const variablesColumns = (titleLabel) => ([
    {
        title: <Localize>TABLES.Column_Title_Key</Localize>,
        dataIndex: 'slug',
        columnIndex: 'SLUG',
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Editable</Localize>,
        dataIndex: 'editable',
        columnIndex: 'EDITABLE',
        sorter: true,
    },
    {
        title: `${ titleLabel } (en)`,
        dataIndex: 'value_en',
        columnIndex: 'VALUE',
        sorter: true
    },
    {
        title: `${ titleLabel } (de)`,
        dataIndex: 'value_de',
        columnIndex: 'VALUE',
        sorter: true
    },

    {
        // title: '',
        dataIndex: 'action',
        align: 'right',
        width: '4%',
    }
]);


const variableData = (variableLists, variables) => {

    if (!variableLists)
    {
        return [];
    }

    return variableLists.map(variableList => {

        return {
            key: variableList.id,
            slug: <VariableFields.Slug variableList={ variableList } variables={ variables } />,
            editable: variableList.editable ? <span style={ { color: 'var(--green)' } }>yes</span> : <span style={ { color: 'var(--red)' } }>no</span>,
            value_en: <VariableFields.Translation id={ variableList?.id } lang="en" initialValue={ variableList?.value } variableList={ variableList } variables={ variables } />,
            value_de: <VariableFields.Translation id={ variableList?.translation?.id } lang="de" initialValue={ variableList?.translation?.value } variableList={ variableList } variables={ variables } />,
            action: <VariableFields.Action variableId={ variableList?.id } variableName={ variableList?.slug } />
        };
    });
};


const VariableListTableHelper = () => {

    const titleLabel = `${ Localize({ children: "TABLES.Column_Title_Label" }).props.children }`;

    return {
        columns: variablesColumns(titleLabel),
        data: variableData,
    }
}

export default VariableListTableHelper;