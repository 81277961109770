import React from "react";
import { Button } from "antd";
import Variable from 'components/variable';
import Icons from "components/icons";
import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault } from "components/layout";
import { Localize } from "components/service";

import './variable.scss';
import { useHistory } from "react-router-dom";




const VariableGroupPage = () => {

    const history = useHistory();

    const basePath = Variable.Const.basePath;

    useActiveMenuItem([ "variable-lists" ], [ "variable-lists" ]);

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_VariableLists" }).props.children,
            path: `${ basePath }`
        }
    ]

    useBreadCrumbs(breadcrumbs);


    const pageNavbar = [
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_VariableLists" }).props.children,
            path: `${ basePath }`
        },
        // {
        //     label: Localize({ children: "NAVBAR_PAGES.Label_GraphicRules" }).props.children,
        //     path: "graphic-rules"
        // }
    ];


    return (
        <PageWrapDefault
            className="page-variables"
            dataExist={ true }
            title={ <Localize>PAGES.Title_Settings</Localize> }
            pageNavbar={ pageNavbar }
            staticPath=""
        >

            <div className="table-action-bar">

                <div className="col"> </div>

                <div className="col">
                    <Button
                        type="primary"
                        icon={ <Icons.Plus /> }
                        onClick={ () => history.push(`${ basePath }/create`) }
                    >
                        <span className="ellipsis"><Localize>GLOBAL.Button_Text_CreateNew</Localize></span>
                    </Button>
                </div>
            </div>

            <Variable.Helpers.TableGroup />

        </PageWrapDefault>
    )
};

export default VariableGroupPage;