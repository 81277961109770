import React from "react";
import { Link } from "react-router-dom";
import RoleConst from "../role-const";


const RoleRoleField = ({ role }) => {

    return (
        Object.keys(role).length ?
            <Link
                className="table-link-underline"
                style={ { color: role?.colorHex } }
                to={ `${ RoleConst.basePath }/${ role?.id }` }>
                <strong>{ role?.title }</strong>
            </Link>
            :
            <strong>&mdash;</strong>
    );
};

export default RoleRoleField


