import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { ROLE_DELETE } from "graphql/mutation/role-gql";
import RoleConst from '../role-const';
import { successNotification, errorNotification } from "components/request-result";


const useMutationRoleDelete = (id) => {


  const history = useHistory();

  const [ _setMutationRoleDelete, { loading } ] = useMutation(ROLE_DELETE,
    {
      update(cache, { data }) {

        const {
          roleDelete: {
            label,
            message
          }
        } = data;

        history.push(RoleConst.basePath);

        cache.evict({
          id: cache.identify({ id, __typename: "Role" })
        });
        cache.gc();

        successNotification({
          title: label.toUpperCase(),
          description: message
        });
      },
      onError(error) {
        errorNotification(error);
      }
    });

  return {
    _setMutationRoleDelete,
    loadingMutationRoleDelete: loading,
  }
};

export default useMutationRoleDelete;