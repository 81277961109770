import Components from './dark-components-style-config';
import Token from './token-style-config';

import { cssVariable } from "graphql/cache";
import { useReactiveVar } from "@apollo/client";


export const DarkTheme = () => {

    const cssVar = useReactiveVar(cssVariable);


    const { Layout, Switch, Table, Sider, Typography, Button, Input, Select, Checkbox, Form, Dropdown, Breadcrumb, Modal } = Components(cssVar);
    const { dark } = Token(cssVar);

    return {
        // algorithm: [ darkAlgorithm ],
        components: {
            Layout,
            Switch,
            Table,
            Sider,
            Typography,
            Button,
            Form,
            Input,
            Select,
            Checkbox,
            Dropdown,
            Breadcrumb,
            Modal,
        },
        token: {
            ...dark,
        },
    }
};