import React from "react";
import { useQuery } from "@apollo/client";
import { GET_USER } from "graphql/query/user-gql";
import { PageWrapDefault } from "components/layout";
import { useActiveMenuItem } from "components/use-hooks";
import UserRoute from 'components/routes/user/user-route';
import { Localize } from "components/service";


const UserPage = ({ match, history }) => {

    useActiveMenuItem([ "users" ], [ "users" ]);

    const path = history.location.pathname.split('/').pop()
    const userID = match ? parseInt(match.params.id) : null;

    const { data: { user } = {}, loading } = useQuery(GET_USER, {
        skip: !userID,
        variables: {
            id: userID
        }
    });

    const pageNavbar = [
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Overview" }).props.children,
            path: "overview"
        },
    ];


    return (
        <PageWrapDefault
            className="page-user"
            loading={ loading }
            title={ path === 'create' ? <Localize>PAGES.Title_CreateUser</Localize> : !loading && user ? `${ user.name } ${ user.surname }` : 'Page 404' }
            dataExist={ path === 'create' ? true : !loading && user }
            pageNavbar={ !loading && user ? pageNavbar : false }
            staticPath={ `/users/${ userID }` }
        >
            <UserRoute
                user={ user }
                history={ history }
                loading={ loading } />

        </PageWrapDefault>
    );
};

export default UserPage;
