import React, { useState } from "react";
import { Form, Input, Button, Switch, Select } from 'antd';

import { variableUseMutation } from '../../hooks';

import { Localize } from "components/service";
import Icons from "components/icons";



const VariableEditForm = ({ variableList, action, inputValue, redirect, variables }) => {

    const [ form ] = Form.useForm();

    const [ submitCount, setSubmitCount ] = useState(0);


    const {
        _setVariableCreateUpdate,
        loadingMutationVariableCreateUpdate,
    } = variableUseMutation.createUpdate({ id: variableList?.id, redirect, variables });


    const handleSubmit = (values) => {

        _setVariableCreateUpdate({
            variables: {
                input: {
                    id: variableList?.id ? variableList?.id : undefined,
                    ...values,
                    group_name: variableList?.id ? variableList?.group_name : inputValue,
                },
            },
        });

        action();
        form.resetFields();
        setSubmitCount(submitCount + 1);
    };


    return (

        <Form
            key={ `form-variable-edit-${ submitCount }` }
            layout="vertical"
            form={ form }
            onFinish={ handleSubmit }>

            <div className="row-grid grid-gap-10 row-gap-0 col-md-2 ant-form ant-form-vertical">

                <Form.Item
                    name="slug"
                    label={ <Localize>FORMS.Input_Label_Key</Localize> }
                    initialValue={ variableList?.slug }
                    rules={ [
                        { required: true, message: <Localize>FORM_RULES.Required_Key</Localize> }
                    ] }
                >
                    <Input name="slug" />
                </Form.Item>

                <Form.Item
                    name="editable"
                    label={ <Localize>FORMS.Input_Label_EditableKey</Localize> }
                    initialValue={ variableList?.editable ?? false }
                    valuePropName="checked"
                    className='form-item-switch'
                >
                    <Switch />
                </Form.Item>

                <Form.Item
                    name="lang"
                    label={ <Localize>FORMS.Input_Label_Language</Localize> }
                    initialValue={ variableList?.lang }
                    rules={ [ { required: true, message: `${ Localize({ children: "FORM_RULES.Required_Choose" }).props.children } ${ Localize({ children: "FORMS.Input_Label_Language" }).props.children }` } ] }
                >
                    <Select
                        showSearch
                        suffixIcon={ <Icons.Arrow /> }
                        placeholder={ `${ Localize({ children: "FORMS.Input_Placeholder_Choose" }).props.children } ${ Localize({ children: "FORMS.Input_Label_Language" }).props.children }` }
                        popupClassName="select-serch-dropdown"
                        optionFilterProp="value"
                    >
                        <Select.Option variableList="en" value="en"><Localize>FORMS.Select_OptionLabel_English</Localize></Select.Option>
                        {/* <Select.Option variableList="de" value="de"><Localize>FORMS.Select_OptionLabel_Deutsch</Localize></Select.Option> */ }

                    </Select>
                </Form.Item>

                <Form.Item
                    name="value"
                    label={ <Localize>FORMS.Input_Label_Name</Localize> }
                    initialValue={ variableList?.value }
                    rules={ [
                        { required: true, message: <Localize>FORM_RULES.Required_Name</Localize> }
                    ] }
                >
                    <Input name="value" />
                </Form.Item>

            </div>

            <div className="form-btn-holder">

                <Button
                    className="light-bg"
                    onClick={ action }
                >
                    <Localize>GLOBAL.Button_Text_Cancel</Localize>
                </Button>

                <Button
                    className="btn-right"
                    type="primary"
                    htmlType="submit"

                    onClick={ () => {
                        form.submit();

                    } }
                    loading={ loadingMutationVariableCreateUpdate }
                >
                    { variableList?.id ? <Localize>GLOBAL.Button_Text_SaveChanges</Localize> : <Localize>GLOBAL.Button_Text_Create</Localize> }
                </Button>
            </div>

        </Form>

    );
};


export default VariableEditForm;
