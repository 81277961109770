import SupplierEditForm from "./supplier-edit-form";
// import SupplierFormItems from "./fields";


const SupplierForms = {
    Edit: SupplierEditForm,
    // Fields: SupplierFormItems,
};

export default SupplierForms;
