import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/client";

import { VARIABLE_LIST_UPDATE, VARIABLE_LIST_CREATE } from 'graphql/mutation/variable-gql';
import { GET_VARIABLE_LISTS } from 'graphql/query/variable-gql';

import VariableConst from '../variable-const';
import { successNotification, errorNotification } from "components/request-result";



const useMutationVariableCreateUpdate = ({ id, redirect = false, variables }) => {


  const VARIABLE_LIST_CREATE_UPDATE = id ? VARIABLE_LIST_UPDATE : VARIABLE_LIST_CREATE;

  const variableCreateUpdate = id ? 'variableListUpdate' : 'variableListCreate';

  const history = useHistory();

  const [ _setVariableCreateUpdate, { loading } ] = useMutation(VARIABLE_LIST_CREATE_UPDATE,
    {
      update(cache, { data }) {

        const {
          [ variableCreateUpdate ]: {
            label,
            message,
          }
        } = data;


        redirect && history.push(VariableConst.basePath);

        successNotification({
          title: label,
          description: message
        });
      },
      onError(error) {
        errorNotification(error);
      },

      refetchQueries: [
        {
          query: GET_VARIABLE_LISTS,
          variables
        },
      ]
    }
  );


  return {
    _setVariableCreateUpdate,
    loadingMutationVariableCreateUpdate: loading,
  }
};

export default useMutationVariableCreateUpdate;

