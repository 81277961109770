import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { SUPPLIER_DELETE } from "graphql/mutation/supplier-gql";
import SupplierConst from '../supplier-const';
import { successNotification, errorNotification } from "components/request-result";


const useMutationSupplierDelete = (id) => {


  const history = useHistory();

  const [ _setMutationSupplierDelete, { loading } ] = useMutation(SUPPLIER_DELETE,
    {
      update(cache, { data }) {

        const {
          supplierDelete: {
            label,
            message
          }
        } = data;

        history.push(SupplierConst.basePath);

        cache.evict({
          id: cache.identify({ id, __typename: "Supplier" })
        });
        cache.gc();

        successNotification({
          title: label.toUpperCase(),
          description: message
        });
      },
      onError(error) {
        errorNotification(error);
      }
    });

  return {
    _setMutationSupplierDelete,
    loadingMutationSupplierDelete: loading,
  }
};

export default useMutationSupplierDelete;