import User from "components/user";
import { Localize } from "components/service";


const supplierUsersColumns = [
    {
        title: <Localize>TABLES.Column_Title_ID</Localize>,
        dataIndex: 'id',
        columnIndex: 'ID',
        sorter: true,
        width: '4%'
    },
    {
        title: <Localize>TABLES.Column_Title_Name</Localize>,
        dataIndex: 'name',
        columnIndex: 'NAME',
        sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_Email</Localize>,
        dataIndex: 'email',
        columnIndex: 'EMAIL',
        width: '13%',
        sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_Phone</Localize>,
        dataIndex: 'phone',
        columnIndex: 'PHONE',
        // sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_Supplier</Localize>,
        dataIndex: 'supplier_id',
        columnIndex: 'SUPPLIER',
        // sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_JobType</Localize>,
        dataIndex: 'job_type',
        columnIndex: 'JOB_TYPE',
        sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_Teams</Localize>,
        dataIndex: 'teams',
        columnIndex: 'TEAMS',
        // sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_Status</Localize>,
        dataIndex: 'status',
        columnIndex: 'STATUS',
        // sorter: true
    },
    {
        // title: '',
        dataIndex: 'action',
        align: 'right',
        width: '4%',
    }
];


const supplierUsersData = (users, supplier) => {

    if (!users)
    {
        return [];
    }

    return users.map(user => {

        return {
            key: user.id,
            id: user.id,
            name: <User.Fields.Info user={ user } />,
            email: user.email ? <a href={ `mailto:${ user.email }` }>{ user.email }</a> : <strong>&mdash;</strong>,
            phone: user.phone ?? <strong>&mdash;</strong>,
            supplier_id: user.supplier_id ?? <strong>&mdash;</strong>,
            job_type: user.job_type ?? <strong>&mdash;</strong>,
            teams: user.team ?? <strong>&mdash;</strong>,
            status: <User.Fields.Status id={ user.id } status={ user.status } />,
            action: <User.Fields.Remove userId={ user.id } modelID={ { supplier_id: supplier.id } } mutation="supplierRemoveUser" userName={ user.name } />
        };

    });
};


const SupplierUsersTableHelper = {
    columns: supplierUsersColumns,
    data: supplierUsersData,
}

export default SupplierUsersTableHelper;