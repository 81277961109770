import React from "react";
import { Form, Input, Button } from 'antd';

import { supplierUseMutation } from '../../hooks';

import { Localize, EntityRemoveButton } from "components/service";
import Icons from "components/icons";



const SupplierEditForm = ({ supplier }) => {

    const [ form ] = Form.useForm();


    const {
        _setSupplierCreateUpdate,
        loadingMutationSupplierCreateUpdate,
    } = supplierUseMutation.createUpdate(supplier?.id);

    const {
        _setMutationSupplierDelete,
        loadingMutationSupplierDelete,
    } = supplierUseMutation.delete(supplier?.id);


    return (

        <Form
            key="edit-supplier-form"
            layout="vertical"
            form={ form }
            className="model-form edit-supplier-form"
            onFinish={ (values) => {
                _setSupplierCreateUpdate({
                    variables: {
                        input: {
                            id: supplier?.id ? +supplier.id : undefined,
                            ...values
                        }
                    }
                })
            } }>

            <Form.Item
                name="title"
                label={ <Localize>FORMS.Input_Label_SupplierName</Localize> }
                initialValue={ supplier?.title }
                rules={ [
                    { required: true, message: <Localize>FORM_RULES.Required_SupplierName</Localize> }
                ] }
            >
                <Input name="title" />
            </Form.Item>

            <div className="row-grid grid-gap-10 row-gap-0 col-md-2">

                <Form.Item
                    name="number"
                    label={ <Localize>FORMS.Input_Label_Number</Localize> }
                    initialValue={ supplier?.number }
                    rules={ [
                        { required: true, message: <Localize>FORM_RULES.Required_Number</Localize> }
                    ] }
                >
                    <Input name="number" />
                </Form.Item>

            </div>

            <div className="form-btn-holder">
                { supplier?.id &&
                    <EntityRemoveButton
                        modalButton={
                            <Button icon={ <Icons.Delete /> }> <span className="ellipsis"><Localize>SUPPLIER.Button_Text_Delete</Localize></span> </Button>
                        }
                        nameEntity={ Localize({ children: "ENTITY.Modal_Title_Supplier" }).props.children }
                        dataNameEntity={ `${ supplier?.title }` }
                        loading={ loadingMutationSupplierDelete }
                        deleteMutation={ _setMutationSupplierDelete }
                        variables={ {
                            id: supplier?.id,
                        } }
                    /> }
                <Button
                    className="btn-right"
                    type="primary"
                    htmlType="submit"
                    icon={ supplier?.id ? <Icons.Edit /> : <Icons.Plus /> }
                    loading={ loadingMutationSupplierCreateUpdate }
                >
                    <span className="ellipsis"> { supplier?.id ? <Localize>GLOBAL.Button_Text_SaveChanges</Localize> : <Localize>GLOBAL.Button_Text_CreateNew</Localize> }</span>
                </Button>
            </div>
        </Form>
    );
};

export default SupplierEditForm;


