import React, { useState } from "react";
import { Modal, ConfigProvider } from "antd";
import { CloseOutlined } from '@ant-design/icons';
import customizeTheme from 'components/customize-theme';



const ModalStandard = ({
    modalButton,
    extraClass = "",
    showModal: parentShowModal, // Control from parent component
    setShowModal: parentSetShowModal, // Control from parent component
    maskClosable = true,
    closable = true,
    defaultShow = false, // Used only if showModal and setShowModal are not provided from parent component
    disabled = false,
    width = 380,
    ...props
}) => {
    // useState hook for managing modal visibility if showModal and setShowModal are not provided
    const [ showModal, setShowModal ] = useState(defaultShow);

    // Determine whether to use the modal control from the parent component or the local component
    const isControlled = typeof parentShowModal !== "undefined" && typeof parentSetShowModal !== "undefined";

    // Function to handle modal visibility based on the control source
    const handleModalVisibility = (visible) => {
        if (isControlled)
        {
            parentSetShowModal(visible); // Control from parent component
        } else
        {
            setShowModal(visible); // Control from local component
        }
    };

    return (
        <>
            { modalButton && React.cloneElement(modalButton, {
                disabled,
                onClick: () => handleModalVisibility(true) // Toggle modal visibility based on control source
            }) }

            <Modal
                className={ `modal-standard ${ extraClass }` }
                centered
                open={ isControlled ? parentShowModal : showModal } // Use the appropriate control variable
                maskClosable={ maskClosable }
                width={ width }
                closable={ closable }
                closeIcon={ <CloseOutlined /> }
                destroyOnClose={ true }
                footer={ null }
                onOk={ () => handleModalVisibility(false) } // Toggle modal visibility based on control source
                onCancel={ () => handleModalVisibility(false) } // Toggle modal visibility based on control source
            >
                <ConfigProvider theme={ customizeTheme.LightTheme() }>

                    { Array.isArray(props.children)
                        ? props.children.map((child, index) =>
                            React.cloneElement(child, { key: index, action: () => handleModalVisibility(false) })
                        )
                        : React.cloneElement(props.children, { action: () => handleModalVisibility(false) })
                    }

                </ConfigProvider>
            </Modal>
        </>
    );
};

export default ModalStandard;
