import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { VARIABLE_GROUP_DELETE } from "graphql/mutation/variable-gql";
import VariableConst from '../variable-const';
import { successNotification, errorNotification } from "components/request-result";


const useMutationVariableGroupDelete = (groupName) => {


  const history = useHistory();

  const [ _setMutationVariableGroupDelete, { loading } ] = useMutation(VARIABLE_GROUP_DELETE,
    {
      update(cache, { data }) {

        const {
          variableListGroupDelete: {
            label,
            message
          }
        } = data;

        history.push(VariableConst.basePath);

        successNotification({
          title: label.toUpperCase(),
          description: message
        });
      },
      onError(error) {
        errorNotification(error);
      }
    });

  return {
    _setMutationVariableGroupDelete,
    loadingMutationVariableGroupDelete: loading,
  }
};

export default useMutationVariableGroupDelete;