import { ModalStandard } from 'components/service';
import VariableForms from '../forms';
import { Localize } from 'components/service';


const VariableSlugField = ({ variableList, variables }) => {

    return (

        <ModalStandard
            width={ 500 }
            extraClass={ 'modal-form' }
            modalButton={
                <strong className='title' style={ { cursor: 'pointer' } }>{ variableList?.slug }</strong>
            }>

            <strong className='modal-title'><Localize>FORMS.Title_EditKey</Localize></strong>

            <VariableForms.Edit variableList={ variableList } variables={ variables } />

        </ModalStandard>
    )
};


export default VariableSlugField;