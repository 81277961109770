import { gql } from '@apollo/client';


export const GET_ROLES_CURSOR = gql`
    query RolesCursor(
        $text: String
        $where: QueryRolesCursorWhereWhereConditions
        $first: Int
        $after: String
    ) {
        rolesCursor(
        text: $text
        where: $where
        first: $first
        after: $after
        ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            total
            count
            currentPage
            lastPage
        }
        edges {
            cursor
            node {
                id
                title
                slug
                colorHex
                description
              }
           }
        }
    }
`;

export const GET_ROLES = gql`
    query GetRoles(
        $text: String,
        $first: Int,
        $page: Int,
        $where: QueryRolesWhereWhereConditions
        $orderBy: [QueryRolesOrderByOrderByClause!]
    ){
        roles(
            text: $text,
            first: $first,
            page: $page,
            orderBy: $orderBy,
            where: $where
        ){
            paginatorInfo{
                total
                count
                currentPage
                perPage
                lastPage
            }
            data {
                    id
                    title
                    slug
                    colorHex
                    description
                    permissions {
                        id
                        relTranslation {
                          id
                          lang
                          slug
                          translation {
                            id
                            slug
                            value
                          }
                        }
                    }
                    created_at
                    updated_at
                }
            }
        }
`;


export const GET_ROLE = gql`
    query GetRole(
        $id: ID,
    ) {
        role(
            id: $id,
        ){
            id
            title
            slug
            colorHex
            description
            permissions {
                id
                relTranslation {
                  id
                  lang
                  slug
                  translation {
                    id
                    slug
                    value
                  }
                }
            }
            created_at
            updated_at
       }
   }
`;