const Components = (cssVar) => {

    return {

        "Input": {
            "colorBgContainer": cssVar[ '--input_colorBgContainer' ],
            "colorBgContainerDisabled": cssVar[ '--input_colorBgContainerDisabled' ],
            "colorTextDisabled": cssVar[ '--input_colorTextDisabled' ],
            "colorBorder": cssVar[ '--input_colorBorder' ],
            "colorError": cssVar[ '--input_colorError' ],
            "colorPrimary": cssVar[ '--input_colorPrimary' ],
            "colorPrimaryActive": cssVar[ '--input_colorPrimaryActive' ],
            "colorText": cssVar[ '--input_colorText' ],
            "colorTextPlaceholder": cssVar[ '--input_colorTextPlaceholder' ],
        },
        "Select": {
            "colorBgContainer": cssVar[ '--red' ],
            "colorBgContainerDisabled": cssVar[ '--select_colorBgContainerDisabled' ],
            "colorText": cssVar[ '--select_colorText' ],
            "colorTextDescription": cssVar[ '--select_colorTextDescription' ],
            "colorTextPlaceholder": cssVar[ '--select_colorTextPlaceholder' ],
            "controlItemBgActive": cssVar[ '--select_controlItemBgActive' ],
            "colorBgElevated": cssVar[ '--select_colorBgElevated' ],
            "colorBorder": cssVar[ '--select_colorBorder' ],
            "colorTextDisabled": cssVar[ '--select_colorTextDisabled' ],
            "colorTextQuaternary": cssVar[ '--select_colorTextQuaternary' ],
            "fontWeightStrong": "600",
        },
    }
}


export default Components;