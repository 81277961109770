import { gql } from '@apollo/client';


export const GET_SUPPLIERS = gql`
    query GetSuppliers(
        $text: String,
        $first: Int,
        $page: Int,
        $orderBy: [QuerySuppliersOrderByOrderByClause!]
    ){
        suppliers(
            text: $text,
            first: $first,
            page: $page,
            orderBy: $orderBy,
        ){
            paginatorInfo{
                total
                count
                currentPage
                perPage
                lastPage
            }
            data {
                id
                title
                number
                type
                usersCount
                users {
                  id
                  name

                }
                description
                created_at
                updated_at
                }
            }
        }
`;


export const GET_SUPPLIER = gql`
    query GetSupplier(
        $id: ID,
    ) {
        supplier(
            id: $id,
        ){
            id
            title
            number
            type
            usersCount
            users {
              id
              name

            }
            description
            created_at
            updated_at
       }
   }
`;