import React from 'react';
import { Form, Select } from 'antd';

import RoleConst from '../../role-const';
import Icons from 'components/icons';
import { Localize } from "components/service";


const { Option } = Select;


const RoleColorSelect = ({
    initialValue,
    name = 'colorHex',

}) => {

    return (
        <Form.Item
            name={ name }
            label={ <Localize>FORMS.Input_Label_Color</Localize> }
            initialValue={ initialValue }
            rules={ [
                { required: true, message: <Localize>FORM_RULES.Required_Color</Localize> }
            ] }
        >
            <Select
                suffixIcon={ <Icons.Arrow /> }
                placeholder={ Localize({ children: "FORMS.Input_Placeholder_ChooseColor" }).props.children }
            >
                <Option key="choose"><Localize>FORMS.Select_OptionLabel_ChooseColor</Localize> </Option>

                { RoleConst.colorHexList.map((item) => (

                    <Option key={ item.text } value={ item.value }>

                        <span style={ { color: item.value } }>
                            { item.text }
                        </span>

                    </Option>

                )) }

            </Select>
        </Form.Item>
    );
}

export default RoleColorSelect;