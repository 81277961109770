import React from "react";
import { Form, Input, Button, /*Skeleton,*/ Checkbox } from 'antd';
// import { useQuery } from "@apollo/client";

// import { GET_PERMISSIONS } from "graphql/query/permission-gql";

import RoleFormItems from "../fields";
import { roleUseMutation } from '../../hooks';

import { Localize, EntityRemoveButton } from "components/service";
import Icons from "components/icons";



const RoleEditForm = ({ role }) => {

    const [ form ] = Form.useForm();

    // const { data: { permissions = {} } = {}, loading } = useQuery(GET_PERMISSIONS, {
    //     variables: {
    //         first: 9999,
    //     },
    //     fetchPolicy: "network-only"
    // });


    let permissionIds = [];
    role?.permissions.map(perm => permissionIds.push(parseInt(perm.id)));

    const {
        _setRoleCreateUpdate,
        loadingMutationRoleCreateUpdate,
    } = roleUseMutation.createUpdate(role?.id);

    const {
        _setMutationRoleDelete,
        loadingMutationRoleDelete,
    } = roleUseMutation.delete(role?.id);


    return (

        <Form
            key="edit-role-form"
            layout="vertical"
            form={ form }
            className="model-form edit-role-form"
            onFinish={ (values) => {
                _setRoleCreateUpdate({
                    variables: {
                        input: {
                            id: role?.id ? +role.id : undefined,
                            ...values,
                            permission_id: [ 1, 2 ] // delete after test
                        }
                    }
                })
            } }>

            <div className="row-grid grid-gap-10 grid-tablet" style={ { gridTemplateColumns: '1fr 220px' } }>

                <Form.Item
                    name="title"
                    label={ <Localize>FORMS.Input_Label_RoleName</Localize> }
                    initialValue={ role?.title }
                    rules={ [
                        { required: true, message: <Localize>FORM_RULES.Required_RoleName</Localize> }
                    ] }
                >
                    <Input name="title" />
                </Form.Item>

                <RoleFormItems.ColorSelect initialValue={ role?.colorHex } />
            </div>

            <br />

            <h5 className="form-heading"><Localize>FORMS.Headig_Text_AppRights</Localize></h5>

            <Form.Item
                name="permission_id"
            // initialValue={  }
            >
                <Checkbox.Group>
                    <div className="form-checkbox-holder row-grid grid-gap-10 col-2 col-md-3">

                        <div className="col">
                            <div className="heading">
                                <h6><Localize>FORMS.Headig_Text_Special</Localize></h6>
                            </div>
                            <Checkbox disabled key='1' value={ 1 }> View only </Checkbox>
                        </div>

                        <div className="col">
                            <div className="heading">
                                <h6><Localize>FORMS.Headig_Text_User</Localize></h6>
                            </div>
                            <Checkbox disabled key='3' value={ 3 }> App login </Checkbox>
                            <Checkbox disabled key='4' value={ 4 }> Change password </Checkbox>
                        </div>

                        <div className="col">
                            <div className="heading">
                                <h6><Localize>FORMS.Headig_Text_Exhibitions</Localize></h6>
                            </div>
                            <Checkbox disabled key='5' value={ 5 }> View Exhibition </Checkbox>
                            <Checkbox disabled key='6' value={ 6 }> Import Exhibition </Checkbox>
                        </div>
                    </div>
                </Checkbox.Group>
            </Form.Item>

            <div className="form-btn-holder">
                { role?.id &&
                    <EntityRemoveButton
                        modalButton={
                            <Button icon={ <Icons.Delete /> }> <span className="ellipsis"><Localize>ROLE.Button_Text_Delete</Localize></span> </Button>
                        }
                        nameEntity={ Localize({ children: "ENTITY.Modal_Title_Role" }).props.children }
                        dataNameEntity={ `${ role?.title }` }
                        loading={ loadingMutationRoleDelete }
                        deleteMutation={ _setMutationRoleDelete }
                        variables={ {
                            id: role?.id,
                        } }
                    /> }
                <Button
                    className="btn-right"
                    type="primary"
                    htmlType="submit"
                    icon={ role?.id ? <Icons.Edit /> : <Icons.Plus /> }
                    loading={ loadingMutationRoleCreateUpdate }
                >
                    <span className="ellipsis"> { role?.id ? <Localize>GLOBAL.Button_Text_SaveChanges</Localize> : <Localize>GLOBAL.Button_Text_CreateNew</Localize> }</span>
                </Button>
            </div>
        </Form >
    );
};

export default RoleEditForm;

/* { loading ? <Skeleton active={ true } paragraph={ { rows: 10 } } />
                   :
                   <Form.Item
                       name="permission_id"
                       initialValue={ permissionIds }
                   >
                       <Checkbox.Group>
                           { permissions.data.map(permission => {

                               if (permission.panel === 'crm')
                               {
                                   return (
                                       <Checkbox
                                           key={ `col-permission-${ permission.id }` }
                                           className='checkbox-outline'
                                           value={ parseInt(permission.id) }
                                       // disabled
                                       >
                                           { permission.label }
                                       </Checkbox>
                                   );
                               }
                               return false;
                           }) }

                       </Checkbox.Group>
                   </Form.Item> } */


