import RoleTableHelper from './role-table-helper';
import RoleUsersTableHelper from './role-users-table-helper';



const RoleHelpers = {
    TableMain: RoleTableHelper,
    TableUsers: RoleUsersTableHelper

}

export default RoleHelpers;