import Supplier from "components/supplier";
import { useBreadCrumbs } from "components/use-hooks";
import { Localize } from "components/service";

const supplierBasePath = Supplier.Const.basePath;


const SupplierOverviewSubPage = ({ supplier, history }) => {


    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Suppliers" }).props.children,
            path: `${ supplierBasePath }`
        },
        {
            label: (supplier && `${ supplier?.title }`) || Localize({ children: "BREADCRUMBS.Label_Create" }).props.children,
            path: (supplier && `${ supplierBasePath }/${ supplier?.id }`) || `${ supplierBasePath }/create`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    return (
        <div className="row-grid col-lg-2">

            <div className="col-left">
                <Supplier.Forms.Edit supplier={ supplier } history={ history } />
            </div>
            <div className="col-right"></div>
        </div>
    )
}

export default SupplierOverviewSubPage;