

const cursorPagination = ({ model, data, loading, fetchMore, label= 'title' }) => {

    const {
        [ model ]: {
            pageInfo,
            __typename: typename,
            edges: node = []
        } = {}
    } = data ?? {};


    const nodes = node.map(
        ({ node }) => ({
            value: node.id,
            label: node[ label ]
        }));


    const fetchMoreAction = () => {
        if (!loading && data)
        {
            fetchMore({
                variables: {
                    after: pageInfo?.endCursor
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {

                    const newEdges = fetchMoreResult[ model ]?.edges;
                    const pageInfo = fetchMoreResult[ model ]?.pageInfo;

                    const newData = {
                        [ model ]: {
                            edges: [
                                ...node,
                                ...newEdges
                            ],
                            __typename: typename,
                            pageInfo
                        }
                    };

                    return newEdges.length ? newData : previousResult;
                }
            });
        }
    }


    const onPopupScroll = e => {

        const { scrollTop, scrollHeight, clientHeight } = e.target;
        if (scrollTop + clientHeight >= scrollHeight - 150)
        {
            fetchMoreAction();
        }
    }


    return {
        onPopupScroll,
        fetchMoreAction,
        node,
        nodes,
        pageInfo
    }
};


export default cursorPagination;