const rowColumn = { key: `empty-data-0` };

const SkeletonTableData = (columns, perPage = 30, className = 'my-0') => {

    const emptyData = [];

    columns?.map(({ dataIndex }) =>
        rowColumn[ dataIndex ] = <div className={ `custom-skeleton ${ className }` }></div>)

    for (var i = 0; i < perPage; i++)
    {

        rowColumn[ 'key' ] = `empty-data-${ i }`;

        emptyData.push(
            { ...rowColumn }
        );

    }

    return emptyData;
}

export default SkeletonTableData;