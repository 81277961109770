import React from "react";
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { roleUseMutation } from '../hooks';
import RoleConst from '../role-const';
import { EntityRemoveButton, Localize } from 'components/service';
import Icons from 'components/icons';



const RoleActionField = ({ roleId, roleName }) => {

    const history = useHistory();

    const {
        _setMutationRoleDelete,
        loadingMutationRoleDelete,
    } = roleUseMutation.delete(roleId);



    const items =
        [
            {
                label: <Localize>MENUS_DROP.Label_Edit</Localize>,
                key: '1',
                icon: <Icons.Edit />,
                onClick: () => history.push(`${ RoleConst.basePath }/${ roleId }`)
            },
            {
                key: '2',
                icon:
                    <EntityRemoveButton
                        modalButton={
                            <span style={ { display: 'flex', alignItems: 'center' } } ><Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize></span>
                        }
                        nameEntity={ Localize({ children: "ENTITY.Modal_Title_Role" }).props.children }
                        dataNameEntity={ roleName }
                        loading={ loadingMutationRoleDelete }
                        deleteMutation={ _setMutationRoleDelete }
                        variables={ {
                            id: roleId,
                        } }
                    />,
            },
        ]


    return (
        <Dropdown
            // trigger={['click']}
            placement="bottomRight"
            menu={ { items } }
            overlayClassName="drop-more-menu"
            arrow
        >
            <EllipsisOutlined className="btn-more" />
        </Dropdown>
    )
};

export default RoleActionField;

