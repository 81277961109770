import React from "react";
import { Link } from "react-router-dom";
import VariableConst from "../variable-const";


const VariableGroupField = ({ variable }) => {

    return (
        Object.keys(variable).length ?
            <Link
                className="table-link-underline"
                style={ { color: variable?.colorHex } }
                to={ `${ VariableConst.basePath }/${ variable?.id }` }>
                <strong>{ variable?.title }</strong>
            </Link>
            :
            <strong>&mdash;</strong>
    );
};

export default VariableGroupField


