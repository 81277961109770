import { Breadcrumb } from "antd";
import { Link } from 'react-router-dom';
import { breadCrumbsVar } from "graphql/cache";
import { useReactiveVar } from "@apollo/client";
// import { HomeOutlined, RightOutlined } from '@ant-design/icons';

import './breadcrumbs.scss';


const Breadcrumbs = () => {

  const breadcrumbs = useReactiveVar(breadCrumbsVar);


  const items = breadcrumbs.length && breadcrumbs.map(item => ({ title: <Link to={ item.path }>{ item.label }</Link> }));

  return (
    <Breadcrumb
      items={ items }
      // separator={ <RightOutlined style={ { fontSize: '11px' } } /> }
      className="main-breadcrumb" />
  )
};

export default Breadcrumbs;


