import React, { useState } from "react";
import { Button, Input } from "antd";
import { useRouteMatch, useHistory } from "react-router-dom";

import { GET_SUPPLIERS } from 'graphql/query/supplier-gql';

import Suppliers from 'components/supplier';
import Icons from "components/icons";
import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault, Tables } from "components/layout";
import { Localize } from "components/service";


import './supplier.scss';

const { Search } = Input;


const basePath = Suppliers.Const.basePath;
const tableHelper = Suppliers.Helpers.TableMain;


const SuppliersPage = () => {

    useActiveMenuItem([ "suppliers" ], [ "suppliers" ]);

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Suppliers" }).props.children,
            path: `${ basePath }`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    const history = useHistory();

    let match = useRouteMatch(`${ basePath }/page/:pageNum`);

    const [ searchText, setSearchText ] = useState("");

    return (
        <PageWrapDefault
            className="page-suppliers"
            dataExist={ true }
            title={ <Localize>PAGES.Title_Suppliers</Localize> }
            staticPath=""
        >
            <Tables.Main
                model="suppliers"
                query={ GET_SUPPLIERS }
                varSet={ { perPage: 50 } }
                routeUrl={ `${ basePath }` }
                currentPage={ match ? parseInt(match.params.pageNum) : 1 }
                searchText={ searchText }
                tableHelper={ tableHelper }
            >
                <div className="table-action-bar">

                    <div className="col">

                        <Search
                            className="filter-search"
                            prefix={ <Icons.Search /> }
                            onChange={ (e) => setSearchText(e.target.value) }
                        />

                        <Button
                            disabled
                            icon={ <Icons.Filter /> }>
                            <span className="ellipsis"><Localize>FORMS.Button_Text_ApplyFilters</Localize></span>
                        </Button>
                    </div>

                    <div className="col">
                        <Button
                            type="primary"
                            icon={ <Icons.Plus /> }
                            onClick={ () => history.push(`${ basePath }/create`) }
                        >
                            <span className="ellipsis"><Localize>GLOBAL.Button_Text_CreateNew</Localize></span>
                        </Button>
                    </div>
                </div>
            </Tables.Main>
        </PageWrapDefault>
    )
};

export default SuppliersPage;